.navigation-container {
    $header-height: 90px;
    $user-section-height: 220px;
    position: fixed;
    left: 0px;
    top: $header-height;
    height: calc(100% - $header-height);
    width: inherit ;
    max-width: inherit;
    background-color: #f5f5f5;
    border: 1px solid #e4e4e4;
    border-radius: 0px 10px 0px 0px;
    z-index: 99;
    overflow: hidden;
    transition: .5s;

    .user-profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: $user-section-height;

        .picture-container {

            height: 100px;
            width: 100px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 3px solid #ededed;
            margin-top: 20px;
            margin-bottom: 10px;
            position: relative;

            img,
            svg {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                color: #dbdbdb;

            }

            svg {
                height: 75%;
                width: 75%;
                border-radius: 0px;

            }

            .badge {
                position: absolute;
                right: -10px;
                top: 0px;
                height: 40px;
                width: 40px;


            }
        }

        .info-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .name {
                background-color: var(--default-color);
                color: #FFF;
                font-size: 20px;
                font-weight: 600;
                padding: 5px 10px;
                border-radius: 5px;
                margin: 0px;
                margin-bottom: 8px;
            }

            .rank {
                background-color: #0ba183;
                color: #FFF;
                font-size: 23px;
                font-weight: 900;
                padding: 7px 20px;
                border-radius: 8px;
            }
        }

    }

    .nav-section {
        padding-right: 10px;
        margin-top: 20px;


        ul {
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            // overflow-y: scroll;
            border: 2px solid #f5f5f5;
            background-color: #FFF;
            border-radius: 0px 5px 0px 0px;
            height: calc(100vh - 20px - $header-height - $user-section-height);
            overflow-y: scroll;

            li {
                list-style: none;
                margin: 0px;
                padding: 0px;
                position: relative;

                a {
                    position: relative;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    text-decoration: none;
                    color: #878383;
                    background-color: #FFF;
                    padding: 10px;
                    font-weight: 600;
                    border-bottom: 3px solid #f5f5f5;
                    cursor: pointer;

                    svg {
                        height: 20px;
                        width: 20px;
                    }

                    .arrow {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 22px;
                        width: 22px;
                    }

                    &:hover,
                    &.active {
                        background-color: var(--default-color);
                        color: #FFF;

                        &~ul {
                            display: flex;
                        }
                    }
                }

                ul {
                    position: absolute;
                    left: 0px;
                    top: 100%;
                    height: max-content;
                    width: 100%;
                    z-index: 9;
                    // padding: 0px 10px;
                    box-sizing: border-box;
                    border: 2px solid #e4e4e4;
                    border-top-width: 0px;
                    border-radius: 0px 0px 5px 5px;
                    padding: 0px;
                    display: none;
                    overflow: hidden;

                    &:hover {
                        display: flex;
                    }

                    li {
                        a {
                            background-color: #ece6e6;
                            padding-left: 20px;

                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:600px) {

    .navigation-container {
        $header-height: 73px;
        $user-section-height: 190px;
        position: fixed;
        top: $header-height;
        left: 0px;
        height: calc(100vh + 15px - $header-height);
        transition: .5s;

        .user-profile {
            height: $user-section-height;

            .picture-container {
                height: 80px;
                width: 80px;
                padding: 3px;
                margin-top: 10px;
                margin-bottom: 10px;

                .badge {
                    right: -5px;
                    height: 30px;
                    width: 30px;
                }
            }

            .info-container {

                .name {
                    background-color: var(--default-color);
                    color: #FFF;
                    font-size: 18px;
                    padding: 3px 7px;
                    margin-bottom: 5px;
                }

                .rank {
                    font-size: 20px;
                    padding: 4px 12px;
                    border-radius: 5px;
                }
            }

        }

        .nav-section {
            padding-right: 5px;
            margin-top: 0px;


            ul {
                overflow-y: scroll;

                li {
                    a {
                        padding: 8px 10px;
                    }

                }
            }
        }
    }
}

 