.refer-team-member-page {
    display: flex !important;
    justify-content: center;
    width: 100%;

    .inner-section {
        width: 95%;
        max-width: 800px;

        .balance-section {
            .grid-section {
                .item {
                    height: 180px;
                    min-width: 280px !important;
                    .top {
                        gap: 0px;

                        svg {
                            height: 60px;
                            width: 60px;
                            padding: 10px;
                            border-radius: 50%;
                            background-color: var(--default-color);
                      color: #FFF !important;
                        }
                        h6{
                            font-size: 20px;
                            margin: 0px;
                            margin-top: 5px;
                            color: #4a4a4a;

                        }
                        strong{
                            font-size: 25px;
                            font-weight: 800;
                        }
                    }
                }
            }
        }

        .back-btn-section {
            padding: 10px 0px;
            display: flex;
            justify-content: right;

            button {
                padding: 5px;
                border: 0px;
                outline: 0px;
                border-radius: 4px;
                font-weight: 800;
                background-color: #ffc106;
                color: #FFF;
            }
        }
    }
}



@media only screen and (min-width:200px) and (max-width:750px) {
    .refer-team-member-page {
        width: 100vw;

        
    }
}