.categories-container {
    .categories-warper {
        margin: auto;
        height: calc(100vh - 100px);
        width: 60%;
        min-width: 300px;
        background-color: #Ffff;
        overflow-y: auto;

        ul,
        li {
            margin: 0px;
            padding: 0px;
            text-decoration: none;
            list-style: none;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            padding: 15px;

            li {
                width: 100%;

                button {
                    width: 100%;
                    border: none;
                    outline: none;
                    text-align: start;
                    padding: 5px 15px;
                    border-radius: 4px;
                    cursor: pointer;
                    background-color: #eff2f5;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 25px;
                        width: 35px;
                        margin-right: 10px;
                    }

                    &:active {
                        scale: 1 !important;
                    }
 
                    &.add-btn {
                        display: inline-block;
                        margin: auto;
                        border: none;
                        outline: none;
                        border-radius: 4px;
                        padding: 5px 13px;
                        background-color: blue;
                        color: #FFFFFF;
                        text-align: center;
                    }

                    .element-container {
                        width: 100%;
                    }

                    .action-container {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        gap: 10px;

                        span {
                            background-color: #fff;
                            height: 25px;
                            width: 25px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.add {
                                background-color: green;
                                color: #FFFFFF;
                            }

                            &.delete {
                                background-color: blue;
                                color: #FFFFFF;
                            }
                        }

                    }
                }

                ul {
                    background-color: #dee5eb;
                    padding: 5px;
                    margin: 0px;

                    li {
                        padding: 0px;
                        margin: 0px;
                        background-color: #FFF;
                        padding: 5px 5px 5px 25px;
                        border-radius: 4px;

                        span {
                            float: right;
                            background-color: blue;
                            color: #FFFFFF;
                            height: 25px;
                            width: 25px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            &:active {
                                transform: scale(.92);
                            }
                        }
                    }
                }

            }
        }
    }

    .add-btn-container {
        width: 100%;
        display: flex;
        padding: 20px;

        .add-btn {
            display: inline-block;
            margin: auto;
            border: none;
            outline: none;
            border-radius: 4px;
            padding: 5px 13px;
            background-color: blue;
            color: #FFFFFF;
        }
    }
}