.admin-daily-task {
  position: relative;

  .add-task-button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      outline: none;
      border: none;
      background-color: var(--default-color);
      color: #FFF;
      padding: 5px 20px;
      border-radius: 4px;
      font-weight: 800;
    }
  }

  .daily-task-list-section {
    display: flex;
    flex-direction: column;
    align-items: center; 

    .daily-task-table {
      .title-section {
        display: flex;
        gap: 10px;
        margin-bottom: 15px;

        button {
          border: none;
          outline: none;
          background-color: #183052;
          color: #FFF;
          padding: 3px 8px;
          border-radius: 7px;

          &:hover {
            opacity: .9;
          }

          &:active {
            transform: scale(.95);
          }
        }
      }

      table {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 2px 2px 0px -1px #e3dede;
        margin-bottom: 30px;

        thead {
          tr {
            background-color: #183052;
            // background-color: #36304a;
            font-weight: 600;
          }
        }

        tbody {
          tr {
            background-color: #FFF;

            &:nth-child(even) {
              background-color: #f5f5f5;
            }
          }
        }

        tr {
          th {
            min-width: 150px;
            color: #FFF !important;

            &.auto {
              min-width: auto;
            }

            &.big {
              min-width: 280px;
            }
            &.ext-big {
              min-width: 320px;
            }
          }

          td {
            border-left: 1px solid #f4ebeb;
            border-right: 1px solid #f4ebeb;
            font-size: 18px;
          }

          th,
          td {
            text-align: center;
            padding: 8px 4px;
            color: #8893ad;

            &.user-profile {
              div {
                display: flex;
                gap: 5px;

                img, svg{
                  height: 35px;
                  width: 35px;
                  border-radius: 50%;
                }
              }
            }

            &.action-btn {
              button {
                border: none;
                outline: none;
                background-color: #765bcb;
                color: #FFF;
                padding: 5px 10px;
                font-weight: 700;
                border-radius: 5px;
                box-shadow: 1px 1px 1px 1px #2e2c2c;

                &:hover {
                  opacity: .8;
                }

                &:active {
                  transform: scale(.95);
                }
              }
            }

          }
        }
      }
    }


  }

  .document-modal {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    left: 0pxf;
    top: 0px;
    backdrop-filter: blur(4px);
    background-color: #927b7b17;

    .main-modal {
      width: 60%;
      min-width: 500px;
      height: 600px;
      background-color: #FFFFFF;
      border-radius: 5px;
      padding: 20px;

      .title-section {
        h6 {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }
      }

      .image-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 4px;
        overflow-y: auto;
        padding: 10px;
        border-radius: 5px;
        background-color: #ede7e7;
        height: 400px;
        overflow-y: auto;

        .list-item {
          height: 200px;
          width: 100%;

          img {
            height: 100%;
            width: 100%;
          }
        }

      }

      .action-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 40px;

        button {
          border: none;
          outline: none;
          font-weight: 600;
          background-color: var(--default-color);
          color: #FFF;
          padding: 5px 15px;
          border-radius: 5px;
          &:disabled{
            opacity: .8;
          }
          &:first-child{
            background-color: #0044ba;
          }
          &:hover{
            opacity: .8;
          }
          &:active{
            transform: scale(.95);
          }
        }
      }

    }

  }
}