.categories { 
  background-color: #FFF;
  border-radius: 20px;
  margin: 0px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  .category-creosol {
    width: 100%;
    .swiper-pagination{
      display: none;
    }
    .categories-cart{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      gap: 10px;
      img{
        height: 50px;
        width: 50px;
        border-radius: 5px;
      }
      p{
        margin: 0px;
        padding: 0px;
        font-weight: 500;
        font-size: 19px;
        text-align: center;
      }
    }
    .categories-skeleton-cart{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      span{
        display: inline-block;
        background-color: #e4e4e4;

      }
      .img{
        height: 50px;
        width: 50px;
        border-radius: 5px;

      }
      .label{
        height: 18px;
        width: 100px;
        border-radius: 3px;

      }
    }
  }
}
@media only screen and (min-width:200px) and (max-width:750px) {
  .categories { 
  padding: 20px 10px;

  }
}