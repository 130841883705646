.add-social-form {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;

    form {
        height: 300px;
        max-width: 600px;
        width: 90%;
        background-color: #eadcdc;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        border-radius: 5px;

        div {
            display: grid;
            grid-template-columns: auto 300px;
            background-color: transparent;

            &.user-list-container {
                position: relative;

                .user-list {
                    width: 300px;
                    position: absolute;
                    top: 100%;
                    right: 0px;
                    background-color: #FFF;
                    border: 1px solid #dcd1d1;
                    border-top: none;
                    display: block;

                    ul {
                        padding: 5px;
                        width: 300px;
                        min-width: 100% !important;

                        li {
                            list-style: none;
                            width: 100%;
                            padding: 5px;
                            cursor: pointer;

                            &:hover {
                                background-color: #ebe1e1;
                            }

                            h6 {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                gap: 5px;
                            }
                        }
                    }
                }
            }

            &.tag-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 5px;

                p {
                    border: 1px solid #e8e0e0;
                    padding: 0px 0px 0px 5px;
                    border-radius: 4px;
                    background-color: #FFF;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #b39292;

                    span {
                        height: 100% !important;
                        margin-left: 5px;
                        width: 18px;
                        background-color: #d1bcbc;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {

                            color: #000;
                        }
                    }
                }
            }

            &.action-btn-container {
                display: flex;
                gap: 15px;
                align-items: center;
                justify-content: center;
                margin-top: 20px;

                button {
                    width: 100px;
                    border: none;
                    outline: none;
                    font-weight: 700;
                    border-radius: 4px;

                    &:nth-child(1) {
                        background-color: blue;
                        color: #FFF;
                    }

                    &:nth-child(2) {
                        background-color: green;
                        color: #FFF;
                    }
                }
            }
        }
    }
}