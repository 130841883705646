.admin-help-line {
  width: 100%; 
  position: relative;

  .add-btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    button {
      border: none;
      outline: none;
      padding: 5px 15px;
      border-radius: 4px;
      font-weight: 700;
      background-color: var(--default-color);
      color: #FFF;
    }
  }

  .social-media-list {
    .list-section {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .notice-cart {
        background-color: #FFFFFF;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;

        img {
          width: 100%;
          height: auto;
          max-height: 300px;
          margin-bottom: 10px;
        }

        h6 {
          font-size: 24px;
          font-weight: 600;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          padding: 0px;
        }
        .see-user {
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #e9ebec;
          position: absolute;
          width: auto;
          height: 25px;
          right: 0px;
          top: 0px;
          gap: 5px;
          cursor: pointer;
          font-size: 12px;
          &:hover {
            background-color: #c7d0d4;
          }
        }
        .date-container{
          position: absolute;
          right: 0px;
          bottom: 0px;
          padding: 5px;
          p{
            font-size: 12px;
            margin: 3px;
          }
        }

        .action-btn-container {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            border: none;
            outline: none;
            padding: 5px 15px;
            background-color: blue;
            color: #FFF;
            font-weight: 700;
          }
        }
      }
    }
  }
}