.payments-page {
    display: flex !important;
    justify-content: center;
    width: 100%;

    .inner-section {
        width: 95%;
        max-width: 800px;

        p {
            margin: 0px;
        }

        .payments-notice {
            background-color: #0F91DD;
            border-radius: 5px;
            padding: 5px;

            p {
                color: #FFF;
                margin: 0px;
                font-size: 20px;
                font-weight: 500;

            }

            &.form-head {
                p {
                    font-weight: 700;
                    font-size: 22px;
                    text-align: center;
                    padding: 5px;
                }
            }
        }

        .back-btn-section {
            padding: 10px 0px;
            display: flex;
            justify-content: right;

            button {
                padding: 5px 10px;
                border: 0px;
                outline: 0px;
                border-radius: 4px;
                font-weight: 800;
                background-color: #ffc106;
                color: #FFF;
                font-size: 18px;
            }
        }

        .form-container {
            .select-section {
                margin-bottom: 20px;
                background-color: #FFF;
                border-radius: 10px;
                padding: 15px;

                .title-section {
                    width: auto;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 20px;

                    p {
                        background-color: #FF9F00;
                        font-weight: 500;
                        margin: 0px;
                        border-radius: 4px;
                        font-size: 16px;
                        padding: 5px;
                    }
                }

                .grid-section {
                    display: flex;
                    width: 100%;
                    gap: 20px;

                    .item {
                        width: 100%;

                        &.payment {
                            .top {
                                padding: 0px;
                                box-sizing: border-box;
                                gap: 0px;

                                img {
                                    margin: 15px;
                                }

                                strong {
                                    width: 100%;
                                    text-align: center;
                                    color: #FFF;
                                    padding: 5px;
                                }

                                .number-section {
                                    width: 100%;
                                    text-align: center;
                                    color: #FFF;
                                    padding: 4px;
                                    background-color: #30bab3;

                                    display: flex;
                                    justify-content: space-between;

                                    p {
                                        color: #FFF;
                                    }

                                    button {
                                        border: none;
                                        outline: none;
                                        background-color: #FFF;
                                        // background-color: green;
                                        // background-color: transparent;
                                        color: #765f5f;
                                        height: 23px;
                                        width: 23px;
                                        padding: 0px;
                                        border-radius: 5px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid #FFF;

                                        svg {
                                            height: 20px;
                                            width: 20px;
                                        }
                                    }

                                }

                            }
                        }

                        .top {
                            padding: 15px;
                            background-color: #FFF;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;
                            box-shadow: 1px 1px 2px 1.5px #0000001d;

                            strong {
                                color: #4f4f4f;
                            }

                            .number-section {
                                width: 100%;
                                text-align: center;
                                color: #FFF;
                                padding: 4px;
                                background-color: #30bab3;

                                display: flex;
                                justify-content: space-between;

                                p {
                                    font-size: 12px;
                                }

                                button {
                                    border: none;
                                    outline: none;
                                    background-color: #FFF;
                                    // background-color: green;
                                    // background-color: transparent;
                                    color: #765f5f;
                                    height: 23px;
                                    width: 23px;
                                    padding: 0px;
                                    border-radius: 5px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 1px solid #FFF;

                                    svg {
                                        height: 20px;
                                        width: 20px;
                                    }
                                }

                            }

                            p {
                                font-weight: 600;
                                color: red;
                                font-size: 17px;

                                strong {
                                    color: red;
                                }
                            }

                            &:hover,
                            &.active {
                                background-color: #f85504bd;
                                color: #FFF !important;
                            }

                            svg,
                            img {
                                height: 40px;
                                width: 40px;
                            }
                        }

                        .bottom {
                            display: flex;
                            justify-content: center;
                            margin-top: 10px;

                            input {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                }
            }

            .input-section-container {
                background-color: #FFF;
                padding: 15px 15px;
                border-radius: 10px;

                .input-section {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 15px;
                    gap: 5px;

                    label{
                        font-size: 19px;
                        font-weight: 500;
                    }
                    input,
                    select {
                        border: 1px solid #e4e4e4;
                        height: 50px;
                        font-weight: 500;
                        font-size: 18px;
                        padding: 0px 5px;

                    }

                }
            }

            .submit-section {
                display: flex;
                justify-content: center;

                button {
                    // height: 30px;
                    background: (--default-color);
                    border: none;
                    outline: none;
                    padding: 5px 20px;
                    background-color: var(--default-color);
                    color: #FFF;
                    font-weight: 800;
                    border-radius: 5px;
                    font-size: 20px;
                }
            }
        }
    }
}



@media only screen and (min-width:200px) and (max-width:750px) {
    .payments-page {
        width: 100vw;

        .inner-section {
            .payments-notice {
                p {
                    font-size: 16px; 
                }

                &.form-head {
                    p {
                        font-weight: 600;
                        font-size: 15px;
                        text-align: center;
                        padding: 5px;
                    }
                }
            }

            .back-btn-section {
                button {
                    padding:  5px 10px;
                    font-size: 16px;

                }
            }

            .form-container {
                .select-section {
                    margin-bottom: 20px;

                    .title-section {

                        p {
                            font-size: 14px;
                        }
                    }

                    .grid-section {
                        display: flex;
                        width: 100%;
                        gap: 15px;

                        .item {
                            width: 100%;

                            &.payment {
                                .top {
                                    padding: 0px;
                                    box-sizing: border-box;

                                    img {
                                        margin: 15px;
                                    }

                                    strong {
                                        width: 100%;
                                        text-align: center;
                                        color: #FFF;
                                        padding: 5px;
                                    }

                                    .number-section {
                                        width: 100%;
                                        text-align: center;
                                        color: #FFF;
                                        padding: 2px;
                                        background-color: #30bab3;
                                        display: flex;
                                        justify-content: space-between;

                                        p {
                                            font-size: 12px;
                                        }

                                        button {
                                            border: none;
                                            outline: none;
                                            background-color: #FFF;
                                            // background-color: transparent;
                                            color: #765f5f;
                                            height: 15px;
                                            width: 15px;
                                            padding: 0px;
                                            border-radius: 2px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 1px solid #FFF;

                                            svg {
                                                height: 12px;
                                                width: 12px;
                                            }
                                        }

                                    }
                                }
                            }

                            .top {
                                padding: 10px 5px;
                                font-size: 12px;

                                p {
                                    font-size: 15px;
                                }

                                svg,
                                img {
                                    height: 30px;
                                    width: 30px;
                                }
                            }

                            .bottom {
                                margin-top: 5px;

                                input {
                                    height: 15px;
                                    width: 15px;
                                }
                            }
                        }
                    }
                }

                .input-section-container {
                    background-color: #FFF;
                    padding: 15px 15px;
                    border-radius: 10px;

                    .input-section {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 15px;
                        gap: 5px; 
                        input,
                        select,
                        label {
                            font-size: 16px;
                            font-weight: 500;

                            option {
                                font-size: 16px; 
                            }

                        }

                    }

                    .submit-section {

                        button {
                            padding: 5px 15px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}