.about-body {
    /* background:rgba(0, 0, 0, 0.7)url('../../assets/images/about_img/istockphoto-1004314632-170667a.jpg'); */
    width: 70%;
    max-width: 700px;
    margin: auto;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
    margin-top: -16px;
    color: darkcyan;
    background: #fff;
    background-blend-mode: darken;
    z-index: 1;
}

.about-body h6 {
    font-size: 1.2rem;
    padding: 2.5px;
    padding-top: 50px;
    color: darkcyan;
}

.about-body h5 {
    font-size: 2.5rem;
    /* text-transform: uppercase; */
}

.about-body p {
    font-size: 1.2rem;
    color: darkcyan;
    text-align: justify;
}

.about-img {
    height: 340px;
    width: 65%;
    max-width: 670px;
    margin: auto;
    position: relative;
}

.about-img img {
    height: 100%;
    width: 100%;
    margin: auto;
    z-index: 5;
}

.about-img::after {
    position: absolute;
    content: "";
    left: 0%;
    top: 50%;
    transform: translateX(-50%);
    background-color: #f5daca;
    height: 50%;
    width: 300vw;
    z-index: -1;

}

.about-footer {
    background-color: #f5daca;
    padding: 20px 0 30px 0;
}

.about-footer div div {
    border-right: 1px dashed black;
}

.about-footer div div:last-child {
    border-right: 0px dashed black;
}

.about-footer div {
    padding-top: 4px !important
}

.about-footer div svg {
    height: 30px;
    width: 20px;
}

.socil div svg {
    text-align: center;
    height: 40px;
    width: 40px;
    margin: 5px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid red;
    cursor: pointer;
}

.facebook {
    color: #0D8AF0;
}

.twiter {
    color: #1D9BF0;
}

.instagram {
    color: #F2089A;
}

.youtube {
    color: #FF0202;
}

.about-footer div {
    padding-top: 40px;
}

.socil div svg:hover {
    border: 1px solid;
    background-color: steelblue;
    color: #fff;

}

.location svg {
    color: #FF0202;
}

.phone svg {
    color: #017AF6;
}

.gmail svg {
    color: #CD4D40;
}

@media only screen and (min-width:200px) and (max-width:1100px) {
    .about-img {
        height: 300px;
    }
}

@media only screen and (min-width:200px) and (max-width:900px) {
    .about-img {
        height: 250px;
    }
}

@media only screen and (min-width:200px) and (max-width:768px) {
    .about-img {
        height: 200px;
    }

    .about-footer div div:nth-child(2) {
        border-right: 0px dashed black;
    }

    .about-footer div div:nth-child(3) {
        border-top: 1px dashed black;
    }

    .socil div svg {
        height: 30px;
        width: 30px;
        margin: 3px;
        padding: 5px;
    }
}

@media only screen and (min-width:200px) and (max-width:850px) {
    .about-body h6 {
        font-size: 1rem;
    }

    .about-body h5 {
        font-size: 2rem;
    }

    .about-body p {
        font-size: .9rem;
    }

    .about-footer h6 {
        font-size: .8rem;
    }

    .about-footer span {
        font-size: .8rem;
    }

}
@media only screen and (min-width:200px) and (max-width:540px) {
    .about-body {
        width: 75%;
    }

    .about-img {
        width: 70%;
        height: 150px;
    }

    .about-body h6 {
        font-size: .89rem;
    }

    .about-body h5 {
        font-size: 1.88rem;
    }

    .about-body p {
        font-size: .9rem;
    }

}
@media only screen and (min-width:200px) and (max-width:440px) {
    .about-body h6 {
        font-size: .7rem;
    }

    .about-body h5 {
        font-size: 1.7rem;
    }

    .about-body p {
        font-size: .9rem;
    }

}