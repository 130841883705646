.admin-user{
  .btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      border: none;
      outline: none;
      padding: 5px 10px;
      background-color: var(--default-color);
      color: #FFF;
      font-weight: 600;
      border-radius: 5px;
      &:hover{
        opacity: .9;
      }
      &:active{
        transform: scale(.9);
      }
    }
  }
  .common-table-section{
    max-width: 1000px;
    .balance-section{
      .grid-section{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px; 
        .item{
          max-width: 100%; 
        }
      }
    }
    .table-section{ 
        height: 400px;
    }

  }
}

@media only screen and (min-width:200px) and (max-width:750px) {
  .admin-user{
    .btn-container{ 
      button{
        font-size: 12px;
      }
    }
    .common-table-section{
      max-width: 100vw !important; 
    }
  }
}