.porduct-one-curd{
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    /* box-shadow: 4px 5px rgba(156, 136, 136, 0.247); */
    box-shadow: 4px 5px 2px rgba(156, 136, 136, 0.247),
    4px 5px 12px rgba(156, 136, 136, 0.151),
    -2px -2px  rgba(156, 136, 136, 0.247);
    cursor: pointer;
    transition: .5s;
}
/* .porduct-one-curd:hover{
    border-radius: 10px;
    box-shadow: 0px 0px rgba(245, 245, 220, 0);

} */
.porduct-one-curd:hover .porduct-details::after{
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(137, 43, 226, 0.226);
    border-radius: 0px 0px 10px 10px;
}
.porduct-one-curd .card-img{
    position: relative;
}
.porduct-one-curd .card-img img{
    height: 150px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}
.porduct-one-curd .card-img span{
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 5px 5px 0 15px;
    border-radius: 130% 0 0px 0px;
    font-size: 1.2rem;
    font-weight: 1000;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.384);
    z-index: 5;
}
.porduct-details{
    position: relative;
    padding: 10px;
    width: 100%;
}
.porduct-one-curd a{
    text-decoration: none;
}
.porduct-one-curd h6{
   height: 40px;
   width: 100%;
   color: black;
   font-weight: 500;
   word-break:break-all;
   overflow: hidden;
   /* overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap; */
}
.porduct-one-curd .price{
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    color: green;
    display: flex;
    align-items: center;
}
.porduct-one-curd .price svg{
    font-size: 1.7rem;
    margin: 0;
    padding: 0;
    color: green;
    display: flex;
    align-items: center;
}
.porduct-one-curd .old-price{
    font-size: 1rem;
    text-decoration: line-through;
    color: rgb(70, 62, 62);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.porduct-one-curd .old-price svg{ 
    font-size: 1.2rem;
    text-decoration: line-through;
    color: rgb(70, 62, 62);
    margin: 0;
    padding: 0;
}
