.search-section{
    display: block;
    width: 100% !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}
.search-section div{
    position: relative;
    width: 60%;
    height: 35px;
    max-width: 500px;
    background-color: #fff;
    border-radius: 50px;
    padding-left: 20px;
}
.search-section div input{
    /* background-color: blue; */
    width: calc(100% - 50px);
    height: 100%;
    border: 0px solid transparent;
    outline: 0px solid transparent;
    padding: 10px;

}
.search-section div svg{
   position: absolute;
   top: 50%;
   right: 20px;
   transform: translateY(-50%);

}
.admin-main-prodcut{
    width: 90% !important;
    margin: auto;
}
.admin-product{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
