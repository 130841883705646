.main-user{
    min-height: 100vh;
    min-width: 100vw;
}
.user-details ul{
    width: 80%;
    margin: auto;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
}
.user-details ul li{
    margin: 0;
    padding: 3px 5px;
    list-style: none;
    display: flex;
    padding: 3px 20%;
    justify-content: space-between;
}
.user-details ul li span{
    margin-left: 30px;
}

.user-details ul li:nth-child(even){
    background-color: rgba(255, 255, 255, 0.384);
}
.user-details ul li:nth-child(odd){
    background-color: #fff;
    color: #000;
}

@media only screen and (min-width:200px) and (max-width:810px) { 
    .user-details ul li {
        padding: 5px 15px;
    }
}
@media only screen and (min-width:200px) and (max-width:440px) { 
    .user-details ul li {
       font-size: .88rem !important;
    }
}

