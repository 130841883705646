.hero-section { 
     .e-commerce-hero-section { 
          width: 100%; 
          margin-top: 20px;
         .slid-item, .slid-item-skeleton{ 
           width: 100%;
           aspect-ratio: 16/7;
           max-height: 550px;
           border-radius: 10px;
           overflow: hidden;
           img, .img{
               height: 100%;
               width: 100%;
               display: inline-block;
               background-color: #e4e4e4;
           }
         }
         
     }
}