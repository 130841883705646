:root {
  --border-color: #f8f8f8;
  --default-color: #f85606;
  /* --default-color: #5a58e6; */
}

@import url('./shared/styles/commonTableStyles.scss');
/* @import url('./shared/styles/DashboardMenu.scss'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(214, 201, 201); */
  background-color: #f5f5f5;

  .dashboard-title {
    background-color: var(--default-color);
    padding: 15px 10px;
    font-weight: 700;
    font-size: 22px;
    margin: 0px;
    color: #FFF;

    @media only screen and (min-width:200px) and (max-width:750px) {
      font-size: 19px;
    }
  }

  .image-viewer {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0px;
    top: 0px;
    z-index: 99999;
    background-color: #FFF;

    .inner-container { 
      width: 100vw;
      position: relative;
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;

      .close-btn {
        position: absolute;
        right: 40px;
        top: 20px;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.17);
        backdrop-filter: blur(5px);

        svg {
          height: 40px;
          width: 40px;
          color: #FFF;
        }

        &:hover {
          opacity: .7;
        }

        &:active {
          transform: scale(.95);
        }
      }

      .view-img {
        width: 100vw;
        max-width: 100vw;
        max-height: 100vh;
        aspect-ratio:  16 / 9;
      }

      .img-list {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 80px;
        backdrop-filter: blur(5px);
        background-color: #00000018;
        /* overflow-x: auto; */
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 5px;
        gap: 5px;

        button {
          height: 100%;
          width: 120px;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 0px;
          margin: 0px;

          &.active {
            border: 2px solid #e4e4e4;
            height: calc(100% + 10px);
            width: 130px;

            margin-top: -5px;
          }

          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .rm-scroll-bar {
    overflow-x: scroll;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0px; 
      height: 0px; 
      display: none;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wraper {
  display: block;
  margin: auto;
  width: 95%;
}

@media only screen and (min-width:200px) and (max-width:950px) {
 
  body{
    .image-viewer { 
  
      .inner-container {    
  
        .close-btn { 
          right: 20px;
          top: 20px;
          svg {
            height: 25px;
            width: 25px;
            color: #FFF;
          } 
        }
        .img-list {  
          height: 60px; 
  
          button { 
            width: 80px; 
  
            &.active { 
              height: calc(100% + 10px);
              width: 100px;
            } 
          }
        }
      }
    }
  }
}
@media only screen and (min-width:200px) and (max-width:650px) {
  .wraper {
    width: 96%;
  }
  body{
    .image-viewer { 
  
      .inner-container {    
  
        .close-btn { 
          svg {
            height: 20px;
            width: 20px; 
          } 
        }
        .img-list {  
          height: 40px; 
  
          button { 
            width: 60px; 
  
            &.active { 
              margin-top: -2px;
              height: calc(100% + 5px);
              width: 65px;
            } 
          }
        }
      }
    }
  }
}