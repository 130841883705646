.common-table-section {
    margin-top: 20px;
    width: 100%;

    .table-title {
        background-color: var(--default-color);
        padding: 15px 10px;
        font-weight: 700;
        font-size: 20px;
        margin: 0px;
        color: #FFF;
    }

    .balance-section {
        margin: 10px 0px;

        .grid-section {
            display: flex;
            width: 100%;
            gap: 20px;
            justify-content: center;

            .item {
                width: 100%;
                max-width: 40%;

                &.payment {
                    .top {
                        padding: 0px;
                        box-shadow: 1px 1px 2px 1.5px #0000001d;

                        img {
                            margin: 15px;
                        }

                        strong {
                            width: 100%;
                            text-align: center;
                            color: #FFF;
                            padding: 5px;
                        }
                    }
                }

                .top {
                    padding: 15px;
                    background-color: #FFF;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;

                    strong {
                        color: #4f4f4f;
                        text-align: center;
                    }

                    p {
                        font-weight: 600;
                        color: red;
                        font-size: 17px;

                        strong {
                            color: red;
                        }
                    }

                    svg,
                    img {
                        height: 40px;
                        width: 40px;
                        color: #4f4f4f !important;
                    }
                }
            }
        }
    }

    .filter-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0px;
        flex-wrap: wrap;
        gap: 5px;

        .input-section {
            display: flex;
            align-items: center;
            gap: 5px;
            flex-wrap: wrap;

            .date {
                display: flex;
                box-shadow: 1px 1px 1px -1px #ac979714;
                border: 2px solid #2fbab3;
                height: 30px !important;
                border-radius: 4px;
                overflow: hidden;

                span {
                    background-color: #2fbab3;
                    padding: 0px 4px;
                    color: #FFF;
                }

                input {
                    border: none;
                }
            }

            input,
            select {
                border: 2px solid #2fbab3;
                border-radius: 4px;
                box-shadow: 1px 1px 1px -1px #ac979714;
                height: 30px !important;
                box-sizing: border-box;
                padding: 5px;
            }

            select {
                color: #FFF;
                background-color: #2fbab3;
            }
        }

        .submit-section {
            button {
                border: 1px solid #ac979714;
                border-radius: 4px;
                box-shadow: 1px 1px 1px -1px #ac979714;
                height: 30px;
                box-sizing: border-box;
                padding: 0px 20px;
                background: #2fbab3;
                color: #FFF;
            }
        }
    }

    .table-section {
        overflow: scroll;
        height: max-content !important;
        max-height: 700px ;
        overflow-x: scroll;
        width: 100%;

        table {
            background-color: #FFF;
            width: 100%;


            thead {
                background-color: #e4e4e4;
            }


            tr {

                th,
                td {
                    min-width: 150px;
                    padding: 5px;
                    border: 1px solid #e4e4e4;
                    text-align: center;
                    font-size: 18px;

                    &.dotted {

                        min-width: 150px;
                        max-width: 150px;
                        overflow: hidden;

                        p,
                        a {
                            display: inline-block;
                            width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-align: start;
                            padding: 0px 3px;
                            margin: 0px;
                            box-sizing: border-box;
                        }

                    }

                    &.small {
                        min-width: 30px !important;
                    }

                    &.big {
                        min-width: 200px;
                    }

                    &.date {
                        min-width: 250px;
                    }

                    &.img {
                        img {
                            height: 40px;
                            width: 40px;
                            border-radius: 4px;
                        }
                    }

                    &.description {
                        min-width: 400px;

                        div {

                            &.ellipsis {
                                height: 40px;
                                overflow: hidden;

                                p {
                                    display: inline-block;
                                    width: 100%;
                                    height: 60px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    line-height: 20px;


                                }
                            }


                        }
                    }

                    &.img-name {
                        text-align: start;
                        min-width: 250px;

                        div {
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            p {
                                margin: 0px;
                                padding: 0px;
                            }

                            img,
                            svg {
                                height: 40px;
                                width: 40px;
                                border-radius: 4px;
                            }
                        }
                    }

                    &.status {
                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            p {
                                padding: 5px 10px;
                                margin: 0px;
                                color: #FFF;
                                background-color: var(--default-color);
                                border-radius: 5px;
                                font-weight: 600;
                            }
                        }

                        &.pending,
                        &.Pending {
                            div {
                                p {
                                    background-color: #2dab0a;
                                }
                            }
                        }

                        &.reject,
                        &.Reject,
                        &.rejected,
                        &.Rejected {
                            div {
                                p {
                                    background-color: red;

                                }
                            }
                        }

                        &.approve,
                        &.Approve,
                        &.approved,
                        &.Approved {
                            div {
                                p {
                                    background-color: #f5540a;

                                }
                            }
                        }


                    }

                    &.btn-container,
                    &.btn-container-center {
                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;
                            height: 100%;
                            width: 100%;

                            button {
                                border: none;
                                outline: none;
                                border-radius: 4px;
                                color: #FFF;
                                background-color: red;
                                width: max-content;

                                &.pending {
                                    background-color: #ed5e11;
                                }

                                &.reject {
                                    background-color: red;
                                }

                                &.approve {
                                    background-color: #2bad07;
                                }

                                &:hover,
                                &:disabled {
                                    opacity: .6;
                                }

                                &:active {
                                    transform: scale(.9);
                                }

                            }
                        }

                    }

                    &.approved {
                        button {
                            background-color: green;
                        }
                    }

                    &.pending {
                        button {
                            background-color: red;
                        }
                    }
                }

                th {
                    border: 1px solid #f8f5f5;

                }
            }
        }
    }



}

@media only screen and (min-width:200px) and (max-width:750px) {
    .common-table-section {
        margin-top: 15px;

        .table-title {
            font-size: 18px;
        }

        .balance-section {

            .grid-section {
                gap: 5px;

                .item {

                    .top {
                        padding: 5px;
                        gap: 4px;
                        font-size: 12px;

                        strong {
                            color: #4f4f4f;
                        }

                        p {
                            font-size: 15px;
                        }

                        svg,
                        img {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }
        }

        .filter-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0px;

            .input-section {
                gap: 5px;
                font-size: 12px;

                .date {
                    height: 25px !important;
                    font-size: 12px;

                    span {
                        padding: 0px 4px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                    }

                    input {
                        max-height: 100%;
                        padding: 0px;
                        padding-left: 4px;
                    }
                }

                input,
                select {
                    height: 25px !important;
                    box-sizing: border-box;
                    padding: 0px 5px;
                }
            }

            .submit-section {
                button {
                    font-size: 12px;
                    height: 20px;
                    padding: 0px 15px;
                    background: #2fbab3;
                    color: #FFF;
                }
            }
        }

        .table-section {

            table {
                font-size: 12px;



            }
        }



    }
}