.admin-slider {
    .header-section {
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        button {
            border: none;
            outline: none;
            padding: 10px 25px;
            border-radius: 5px;
            font-weight: 800;
            font-size: 19px;
            background-color: #95cf39;
            color: #FFF;
            border: 1px solid #FFF;
            outline: 2px solid #95cf39;

            &:hover,
            &.active {
                background-color: #78a72b;
            }
        }
    }
}