.delet_slider{
    width: 100% ;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.delet_slider .balance-transfer-section div{
    width: 100px !important;
    height: 40px !important;
}
.delet_slider img{
    width: 100%;
    height: 150px;
}

.delet_slider button{
    width: 100px;
    height: 40px;
}