.navbar {
    .search-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .search-body {
            display: flex;
            position: absolute;
            gap: 5px;
            align-items: center;
            padding: 5px 8px;
            height: 50px;
            width: 400px;
            border-radius: 30px;
            border: 1px solid var(--default-color);

            input,
            button {
                border: none;
                outline: none;

            }

            input {
                width: 100%;
                height: 100%;
            }

            svg {
                height: 35px;
                width: 35px;
                color: var(--default-color);

            }

            button {
                height: 100%;
                padding: 0px 20px;
                border-radius: 30px;
                background-color: var(--default-color);
                color: #FFF;
                font-weight: 700;

                &:hover {
                    opacity: .8;
                }

                &:active {
                    transform: scale(.95);
                }

            }
        }

    }

    .nav-item {
        a {
            width: max-content;
        }
    }

    .nav-logo {
        display: none;
    }
}

.navbar .header-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar .help-section {
    background-color: #217e97;
    color: #FFF !important;
    border-radius: 4px;
    margin-right: 10px;
    height: 100%;

}

.navbar .help-section.mobile {
    display: none;

}

.navbar .help-section a,
.navbar .help-section svg {
    color: #FFF !important;
    font-size: 1.1rem;
    font-weight: 600;

}

.navbar .help-section a {
    padding: 5px 15px;
    display: flex !important;
    align-items: center;
    height: 100%;
}

.navbar .help-section svg {
    height: 25px;
    width: 25px;
    color: #FFF !important;
    margin-right: 5px;

}

.menu-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgb(230, 230, 223);
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.menu-icons svg {
    margin: 0 5px;
    font-size: 1.4rem;
    color: #000;
}

.menu-icons span {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-size: 1rem;
    color: #fff;
    height: 15px;
    width: 15px;
    background-color: blueviolet;
    padding: 12px;
    border-radius: 50%;
}

.logo {
    height: 80px;
    width: 120px;
}

@media only screen and (min-width:200px) and (max-width:1000px) {
    .navbar {
        max-width: 100vw;

        .search-container {
            width: max-content;

            .search-body {
                padding: 4px 4px;
                height: 40px;
                width: 300px;


                svg {
                    height: 35px;
                    width: 35px;

                }

                button {
                    padding: 0px 10px;
                    border-radius: 30px;

                }
            }
        }

        .navbar-container {
            position: fixed;
            left: 0px;
            top: 0px;
            background-color: #FFF;
            width: calc(100vw - 150px);
            min-width: 350px;
            height: 100vh;
            z-index: 999;
            padding: 10px;
            border-right: 2px solid #cbc9c9;
            height: 100% !important;

            .nav-logo {
                display: inline-block;
                width: 100%;
                position: relative;

                img {
                    height: 80px;
                    width: 120px;
                }

                .close-btn {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    border: none;
                    outline: none;
                    padding: 0px;
                    background-color: transparent;

                    svg {
                        height: 30px;
                        width: 30px;
                    }
                }
            }

            .navbar-nav {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                width: 100%;

                li {
                    &.menu-icons {
                        margin-top: 20px;
                        margin-left: 10px;
                    }

                    a {
                        display: block;
                        width: 100%;
                        padding: 10px 15px;
                        border-bottom: 1px solid #e4e4e4;
                        font-weight: 600;
                        color: #595959;

                        &.active {
                            background-color: var(--default-color);
                            color: #FFF;
                        }

                        &.cart-link {
                            height: 55px;
                            width: 55px;
                            display: flex;
                            min-height: 55px;
                            align-items: center;
                            justify-content: center;
                            border: none;

                            svg {
                                height: 30px;
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:200px) and (max-width:650px) {
    .navbar {

        .search-container {

            .search-body {
                height: 30px;
                width: 210px;


                svg {
                    height: 30px;
                    width: 30px;

                }

                button {
                    padding: 0px 8px;
                    border-radius: 30px;
                    font-size: 14px;
                    font-weight: 800;

                }
            }
        }
    }

    .navbar .help-section.mobile {
        display: block;

    }

    .navbar .help-section.desktop {
        display: none;

    }

    .logo {
        height: 60px !important;
        width: 100px !important;
    }

    .navbar-container ul li a {
        font-size: 1rem;
    }

    .menu-icons {
        margin-top: 12px;
    }

}