.admin-add-daily-task {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;

  .header-section,
  .wrap-contact2 {
    width: 790px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 1px 1px 1px #e9e2e2;
  }

  .header-section {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    button {
      border: none;
      outline: none;
      padding: 10px 25px;
      border-radius: 5px;
      font-weight: 800;
      font-size: 19px;
      background-color: #95cf39;
      color: #FFF;
      border: 1px solid #FFF;
      outline: 2px solid #95cf39;

      &:hover,
      &.active {
        background-color: #78a72b;
      }
    }
  }

  .wrap-contact2 {
    .contact2-form {
      width: 100%;
      padding: 72px 55px 90px;

      .contact2-form-title {
        display: block;
        font-family: Poppins-Bold;
        font-size: 39px;
        color: #333;
        line-height: 1.2;
        text-align: center;
        padding-bottom: 20px;

      }

      .reward-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 0;
        height: 400px;
        // background-color: #f4f4f4;

        .circle {
          position: relative;
          width: 400px;
          height: 400px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Arial, sans-serif;
          overflow: hidden;
          border: 1px solid green;

          &::after {
            position: absolute;
            content: "0%";
            left: 50%;
            top: 50%;
            font-size: 20px;
            font-weight: 600;
            color: #000 !important;

          }

        }

        .discretion {
          h6 {
            font-weight: 700;
            font-size: 19px;
          }

          .list {
            display: flex;
            flex-direction: column;
            gap: 8px;

            // padding-left: 10px;
            div {
              display: flex;
              align-content: center;
              gap: 4px;
              margin: 0px;
              padding: 0px;

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                background-color: var(--itemCol);
                color: #FFF;
                border-radius: 3px;
                font-weight: 600;
              }

              strong {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 800;
                font-size: 19px;
              }
            }
          }
        }

        .circle::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: inherit;
          z-index: 1;
        }

        .segment {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          color: #fff;
          font-weight: bold;
          z-index: 2;
          clip-path: polygon(50% 50%, 0 0, 100% 0);
          /* Adjust for each segment */
        }

        /* Adjust clip-path for each segment to cover correct area */
        // .segment-1 {
        //   clip-path: polygon(50% 50%, 0 0, 100% 0);
        // }

        // .segment-2 {
        //   clip-path: polygon(50% 50%, 100% 0, 100% 100%);
        // }

        // .segment-3 {
        //   clip-path: polygon(50% 50%, 100% 100%, 0 100%);
        // }

        // .segment-4 {
        //   clip-path: polygon(50% 50%, 0 100%, 0 0);
        // }
      }

      .validate-input {
        width: 100%;
        position: relative;
        margin-bottom: 15px;
        height: 50px;

        &.duel-input {
          display: flex;
          gap: 20px;

          div {
            width: 50%;
            position: relative;
          }
        }

        &.add-more-section {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;

          button {
            border: none;
            outline: none;
            font-weight: 500;
            padding: 5px 8px;
            border: 1px solid #c1adad;

            strong {
              font-weight: 1000;
            }

            &:hover {
              opacity: .7;
            }

          }

        }

        &.calender-section,
        &.auto-approve,
        &.image-section {
          display: flex;
          position: none;
          justify-content: space-between;
          align-items: center;

          span,
          input {
            position: relative;
          }

          input {
            width: 200px;
          }
        }

        &.auto-approve {
          input {
            appearance: none;
            width: 60px !important;
            height: 30px !important;
            background-color: #FFF;
            border-radius: 20px;
            border: 1px solid blue;
            transition: .5s;

            &::after {
              position: absolute;
              content: "";
              height: 25px;
              width: 25px;
              top: 50%;
              transform: translate(0%, -50%);
              border-radius: 50%;
              left: 3px;
              background-color: blue;
              transition: .5s;
            }

            &:checked {
              background-color: blue;

              &::after {
                transform: translate(100%, -50%);
                background-color: #FFF;
              }
            }
          }
        }

        &.image-section {
          input {
            border: none;
          }
        }


        input,
        textarea {
          background-color: transparent;
          width: 100%;
          position: absolute;
          bottom: 0px;
          left: 0px;
          outline: none;
          border: none;
          z-index: 2;
          border-bottom: 2px solid #a19a9a;
          padding-bottom: 5px;

          &:focus {
            border-bottom: 2px solid #776868;

          }

          &:focus+span,
          &.fill+span {
            bottom: 50%;
          }



        }


        span {
          position: absolute;
          bottom: 0px;
          left: 0px;
          // width: 100%;
          padding-bottom: 5px;
          transition: .5s;
          z-index: 1;
          color: #a19a9a;
          font-weight: 700;

        }

        .enter-btn {
          position: absolute;
          right: 10px;
          top: 50%;
          background: #e4e4e4;
          border: none;
          outline: none;
          font-size: 12px;
          padding: 2px 4pcx;
          border-radius: 3px;
          font-weight: 600;
          display: none;
          cursor: pointer;
          z-index: 9;

          &:hover {
            opacity: .7;
          }

          &:active {
            transform: scale(.9);
          }

          &.fill {
            display: inline-block;
          }

        }

        .list-container {
          height: 200px;
          width: 100%;
          background-color: #FFF;
          // background-color: red;
          position: absolute;
          top: 53px;
          left: 0px;
          z-index: 999;
          box-shadow: 0px 1px 1px 1px #ccbdbd59;
          border-radius: 0px 0px 10px 10px;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          gap: 3px;

          button {
            background-color: #e4e4e4;
            border: none;
            outline: none;
            width: 100%;
            padding: 5px 20px;
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 10px;

            img, svg{
              height: 40px;
              width: 40px;
              border-radius: 50%;
              border: 1px solid #cabcbc;
              background-color: #FFF;
            }

          }
        }
      }

      .selected-withdraw-amounts {
        ul {
          padding: 0px;
          margin: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 10px;

          li {
            padding: 0px;
            padding-left: 4px;
            list-style: none;
            border: 1px solid #e4e4e4;
            display: flex;
            align-items: center;

            button {
              background-color: #e4e4e4;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 2px;
              height: 100%;
              box-sizing: border-box;
              padding: 0px 4px;
              border: none;
              outline: none;
              margin: 0px;
              margin-left: 4px;
            }
          }
        }
      }

      .container-contact2-form-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          background-color: var(--default-color);
          color: #FFF;
          padding: 5px 25px;
          border-radius: 5px;
          font-weight: 800;
          outline: none;
          border: none;

          &:active {
            transform: scale(.9);
          }

          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }

  .test {
    position: sticky
  }

}