 
.dashbord-header{ 
    width: 100vw;
    height: 75px;
}
.dashbord-header .navbar{
    background-color: #f5f5f5;
    z-index: 99;
    width: 100vw;
    height: 75px;
    position: fixed ;
    top: 0px ;
    left: 0px ;
    display: flex;
    justify-content: center;
}
.dashbord-header .container-fluid {
    background-color: #FFF;
    color: #000;
    padding: 5px 10px;  
    width: 100%;
    box-sizing: border-box;

}
.dashbord-header .notification-icon-container {
   color: #000;
   margin-right: 10px;
   position: relative;
   text-decoration: none;
   display: none;

}
.dashbord-header .notification-icon-container-pc { 
   display: inline-block;
}
.dashbord-header .notification-icon-container svg{
    height: 35px;
    width: 35px;
}
.dashbord-header .notification-icon-container span{
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: red;
    color: #FFF;
    border: 1px solid #FFF;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    font-weight: 800;
    top: -10px;
    right: -5px;
}

.dashbord-nav-items ul li a {
    color: #000 !important;
}

.dashbord-header .navbar-light .navbar-toggler-icon {
    background-image: url('../../../../assets/images/nav_icon/icons8-menu-64.png') !important;
}

.dashbord-control-icon  {
    
}
.dashbord-control-icon svg {
    height: 26px;
    width: 26px;
    display: none;


}

 
 @media only screen and (min-width:200px) and (max-width:800px) {
    .dashbord-control-icon svg {
        height: 26px;
        width: 26px;
        display: block;
    
    
    }
 }

 