.balnce-section div {
    width: 70%;
    height: 140px;
    margin: 30px auto;
    border: 1px solid #fff;
}

.notice-section marquee {
    font-size: 1.4rem;
}
.user-dashboard-page{
    max-width: 800px !important; 
    background-color: #FFF;
    padding: 10px;
}
.user-dashboard-page .dashboard-common-cart {
    width: 100%;
    // max-width: calc(100vw - 20px) !important;
    padding: 5px;
    border: 1px solid #deede4 !important;
    background-color: transparent !important;
    margin-top: 20px;
    border-radius: 4px;

    .inner-container {
        background-color: #deede4 !important;
        border-radius: 5px;
        display: inline-block;
        width: 100%;
        padding: 5px;
        h5{
            font-size: 17px;
        }
    }


}

.active-btn-section {
    h5 {
        line-height: 30px;

        strong {
            // background-color: var(--default-color);
            color: yellowgreen;
            padding: 5px 8px;
            border-radius: 4px;
        }
    }

    div {
        background-color: transparent !important;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border: 1px solid #481818;
            // background-color: var(--default-color);
            background-color: blue;
            border-radius: 5px;
            color: #FFF;
            font-weight: 600;
            font-size: 19px;
            cursor: pointer !important;
        }
    }
}

.balnce-section div p {
    margin: 0;
    padding: 0;
}

.balnce-section div .tk {
    font-size: 1.6rem;
}

.balnce-section div:nth-child(1) {
    /* background-color: #8C43AC !important; */
    background-color: indigo !important;
}

.balnce-section div:nth-child(2) {
    background-color: darkmagenta !important;
}

.balnce-section div:nth-child(3) {
    background-color: darkmagenta !important;
}

.balnce-section div:nth-child(4) {
    background-color: darkmagenta !important;
}

.balnce-section div:nth-child(5) {
    background-color: darkmagenta !important;
}

.balnce-section div:nth-child(1) svg {
    background-color: indigo !important;
}

.balnce-section div:nth-child(2) svg {
    background-color: darkmagenta !important;
}

.balnce-section div:nth-child(3) svg {
    background-color: darkmagenta !important;
}

.balnce-section div:nth-child(4) svg {
    background-color: darkmagenta !important;
}

.balnce-section div:nth-child(5) svg {
    background-color: darkmagenta !important;
}

.balnce-section div svg {
    height: 35px;
    width: 35px;
    padding: 7px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-top: -45px;
}

.withdraw-notice .inner-notice {
    background-color: #2fbab1;
    color: #FFF;
    padding: 10px 0px; 

    p {
        position: absolute;
        left: 6px;
        background-color: #FFF;
        color: var(--default-color);
        border-radius: 5px;
        font-weight: 1000;
        font-size: 19px;
        height: 80%;
        top: 50%;
        transform: translateY(-50%);
        // padding-bottom: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        display: inline-block;
        padding-top: 0px !important;
    }
}

.withdraw-notice p {
    color: blue;
}

.user-dashboard-page .dashboard-user-info {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 30px auto;
    /* border: 1px solid var(--default-color);  */
    color: #3A476B;
    margin-bottom: 0px;
    border-radius: 5px;
    background-color: #FFF !important;
    box-shadow: 1px 1px 5px #c0b3b398,
        1px 1px 7px #c0b3b352;

}

.user-dashboard-page .rafael-section {
    position: relative;
    width: 100%;
    height: 100%;
    color: #3A476B;
    margin-bottom: 0px;
    border-radius: 5px;
    background-color: transparent !important;
    padding: 5px;

}

.user-dashboard-page .rafael-section h6 {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 700;
}

.user-dashboard-page .rafael-section p {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-weight: 400;
    color: #000;
}

.user-dashboard-page .rafael-section div {
    display: flex;
    justify-content: center;
    gap: 15px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    color: #000;
    background-color: transparent !important;

}

.user-dashboard-page .rafael-section div button {
    border: none;
    outline: none;
    border-radius: 4px;
    background-color: var(--default-color);
    color: #fff;
    padding: 5px 15px;
}

.user-dashboard-page .rafael-section div button:active {
    transform: scale(.9);
}

.user-dashboard-page .rafael-section div button:hover {
    opacity: .9;
}

.user-dashboard-page .social-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    background-color: #FFF;
padding: 5px;
    &.single-item{
    grid-template-columns: repeat(1, 1fr);

    }
}



.user-dashboard-page .social-container div {
 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background-color: #FFF;
    /* border: 1px solid #FFF; */
    padding: 5px;
    width: 100%;
}

.user-dashboard-page .social-container div img {
    height: 90px;
    width: 90px;
    border-radius: 50%; 
}

.user-dashboard-page .social-container div button {
    border: none;
    outline: none;
    border-radius: 4px;
    font-weight: 700;
    padding: 3px 8px;
    background-color: #ef5705;
    color: #FFF;
}

.user-dashboard-page .social-container div h6 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}




.user-dashboard-page input {
    height: 26px;
    width: 100%;
}

.user-dashboard-page form input {
    height: 26px;
    width: 100%;
}

.user-dashboard-page form select {
    height: 26px;
    width: 100%;
}

.dashboard-user-info .user-name {
    /* background-color: var(--default-color); */
}

.dashboard-user-info .user-name strong {
    background-color: var(--default-color);
    padding: 4px;
    border-radius: 3px;
    color: #FFFFFF;
}

/* .dashboard-user-info h5 {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    background-color: transparent;
} */

.dashboard-user-info p {
    padding: 0;
    margin: 0;
    padding-left: 10px;
}

.dashboard-user-info span {
    width: initial;
    padding-left: 10px;
}

.dashboard-user-info div {
    background-color: transparent !important;
    border: 1px solid #287DB6;
    padding: 0;
    margin-top: 5px;
}

.dashboard-sub-section {
    background-color: rgba(255, 0, 0, 0) !important;
}

.balance-transfer-history-section {
    margin: 0;
    padding: 0;
}

.balnce-section span {
    font-size: 1.3rem;
}

.dashbord-active-btn {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    font-size: .8rem !important;
}

.dashbord-active-btn {
    font-size: .8rem !important;
}

@media only screen and (min-width:200px) and (max-width:1000px) {
    .user-dashboard-page {
        width: 85% !important;
    }

    .balance-transfer-history-section {
        width: 85% !important;
    }

    .dashboard-user-info h5 {
        font-size: 1.1rem;
    }

    .dashboard-user-info p {
        font-size: 1rem;
    }

}


@media only screen and (min-width:200px) and (max-width:790px) {
    .user-dashboard-page {
        width: 100vw !important;
    }

    .user-dashboard-page .social-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        background-color: #FFF;
    }

    .balance-transfer-history-section {
        width: 90% !important;
    }

    .balance-transfer-history-section table th {
        font-size: .8rem;
    }

    .balance-transfer-history-section table td {
        font-size: .77rem;
    }

    .dashboard-user-info h5 {
        font-size: 1.1rem;
    }

    .dashboard-user-info p {
        font-size: 1rem;
    }

    .user-dashboard-page form {
        margin: auto;
    }

    .user-dashboard-page form div {
        flex-direction: column;
        margin: 0px !important;
    }

    .payment-provider-section div {
        flex-direction: row !important;
        width: 100% !important;
    }

    .sim-contianer label {
        display: block;
        width: 100%;
        padding-left: 10px;
    }

    .sim-contianer div div {
        display: flex !important;
    }

    /* .user-dashboard-page form  .sim-contianer div{
        flex-direction: row;
        margin: 0px !important;
    } */
    .user-dashboard-page form .sim-contianer div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .sim-provider-section div img {
        height: 45px !important;
        width: 45px !important;
        margin: 0;
        padding: 0;
    }

    .user-dashboard-page form .sim-contianer div div {
        display: flex !important;
        flex-direction: column !important;
    }

    .user-dashboard-page form .sim-conditon {
        flex-direction: row;
        margin: 0px !important;
    }

    .user-dashboard-page form div label {
        margin-bottom: 5px;
    }

}

@media only screen and (min-width:200px) and (max-width:690px) {
     

    .balnce-section div {
        height: 125px;
        margin: 30px auto;
        border: 1px solid #fff;
    }

    .balance-transfer-history-section {
        width: 93% !important;
    }

    .dashboard-user-info h5 {
        font-size: 1rem;
    }

    .dashboard-user-info p {
        font-size: .9rem;
    }

    .user-dashboard-page h4 {
        /* font-size: .9rem !important; */
    }

    .user-dashboard-page marquee {
        /* font-size: .83rem !important; */
    }

    .balnce-section div p {
        font-size: 1.1rem;
    }

    .balnce-section div .tk {
        font-size: 1.6rem;
    }

    .balnce-section div p span {
        font-size: 1.4rem;
    }
}

@media only screen and (min-width:200px) and (max-width:550px) {
   

    .dashboard-user-info h5 {
        font-size: .9rem;
    }

    .dashboard-user-info p {
        font-size: .8rem;
    }

    .user-dashboard-page h4 {
        /* font-size: .8rem !important; */
    }

    .user-dashboard-page marquee {
        /* font-size: .78rem !important; */
    }

    .balnce-section div p {
        font-size: .9rem;
    }

    .balnce-section div .tk {
        font-size: 1.6rem;
    }

    .balnce-section div p span {
        font-size: 1.2rem;
    }
}