.notice-section {
    width: 70%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
    background-color: red;
    margin-left: 20px;

    .title-container h4 {
        background-color: var(--default-color);
        font-size: 1.5rem;
        font-weight: 800;
        width: 100%;
        padding: 15px 5px;
        margin-bottom: 0;
        color: #FFF; 
    }

    .list-section {
display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
     

        .notice-cart {
            background-color: #FFFFFF;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            img {
                width: 100%;
                height: auto;
                margin-bottom: 10px;
            }

            h6 {
                font-size: 24px;
                font-weight: 600;
            }

            p {
                font-size: 18px;
                font-weight: 400;
                padding: 0px;
                margin: 0px;
            }
            span{
                font-size: 14px;
                font-weight: 500;
                color: #a27272;
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:750px) {
    .notice-section {
        width: 95%;
    }
}