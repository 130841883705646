.authentication form {
    border: 1px solid #ABABAB;
    padding: 50px;
    border-radius: 10px;
    width: 570px;
    margin: auto;
    background-color: #FFFFFF;
}

.authentication form .referral-section {
    position: relative;
}

.authentication form .referral-section input {
    color: var(--default-color);
    height: 47px !important;
}

.authentication form .referral-section .user-list {
    position: absolute;
    width: 100%;
    top: 35px;
    left: 10px;
    background-color: #FFF !important;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 1px 1px #b0a0a076, 1px 1px 1px 3px #b0a0a049;
    height: 100px;
    overflow: auto;
}

.authentication form .referral-section .user-list button {
    outline: none;
    border: none;
    border-bottom: 1px solid #f4eeee;
    text-align: start;
    padding: 3px 5px;
    background-color: transparent;
}

.authentication form .referral-section .user-list button:hover {
    background-color: #e3d6d6;
}

.authentication form h6,
p {
    padding: 0 10px;
}

.authentication form h6 {
    font-weight: 700;
    font-size: 1.5rem;
}

.authentication .eye-container,
.authentication .icon-container {
    position: relative;
}

.authentication .eye-container input {
    padding-right: 25px;
}

.authentication .eye-container svg {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.authentication .eye-container .eye {
    position: absolute;
    left: calc(100% - 25px) !important;
    top: 50%;
    transform: translateY(-50%);
}

.authentication .eye-container {
    position: relative;
}

.authentication .icon-container input {
    padding-left: 25px;
}

.authentication .icon-container svg {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}


.authentication form input,
.authentication form .notice-container {
    display: block;
    margin: 10px;
    width: 100%;
    padding: 3px 8px;
    border: none;
    color: #000;
    /* border-bottom: 1.7px solid #C5C5C5; */
    border: 1.7px solid #C5C5C5;
    border-radius: 4px;
    outline: yellow;
    height: 85px;
    font-size: 1.3rem;

}

.authentication form input {
    height: 47px;

}

.authentication form label {
    display: block;
    margin: 10px;
    width: 100%;
    padding: 3px 4px 1px 4px;
    border: none;
    color: #000;
    font-size: 1.4rem;
    font-weight: 600;
}

.authentication form .remember-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.authentication form .remember-section div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.authentication form .upline-container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    gap: 10px;
}

.authentication form  .upline-container .img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
    img,svg{
        height: 35px;
        width: 35px;
        color: #e4e4e4;
    border-radius: 50%;

    }
}
.authentication form .upline-container .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.authentication form .upline-container .info p {
    color: #daa126 !important;
    padding: 0px !important;
    margin: 0px;

}

.authentication form .upline-container p span {
    color: blue;

}

.authentication form .remember-section p {
    margin: 0px 0px 1px 0px;
    font-size: 18px;
    font-weight: 500;
}

.authentication form .remember-section .forgot-password {
    color: red;
    text-decoration: none;
    font-weight: 500;
}

.authentication form input[type=checkbox] {
    height: 12px;
    width: 12px;
    display: inline;
}

.authentication form input::placeholder {
    color: #000;
}

.authentication form input:focus {
    border: none;
    border: 2px solid #F9A51A;
    color: #000;
}

.authentication form input[type="submit"] {
    background-color: var(--default-color);
    color: #FFF;
    padding: 5px 0;
    font-weight: 700;
    border: none;
    margin-top: 10px !important;
    height: 47px;
}

.socil-container {
    padding: 0 50px;
    border-radius: 10px;
    width: 570px;
    margin: auto;
    cursor: pointer;
}

.socil-container div {
    border: 1px solid #ABABAB;
    border-radius: 70px;
    margin: 10px auto;
    display: flex;
}

.socil-container div p {
    text-align: center;
    margin: auto;
    font-size: 1rem;
    font-weight: 500;
}

.forgot-password {
    float: right;
    cursor: pointer;
    text-decoration: underline;
}

.slocil-after {
    position: relative;
    text-align: center;
    font-weight: 500;
    margin: 20px auto;
}

.slocil-after::before {
    position: absolute;
    content: " ";
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    background-color: #ABABAB;
    height: 1px;
    width: 40%;
}

.slocil-after::after {
    position: absolute;
    content: " ";
    top: 50%;
    transform: translateY(50%);
    right: 30px;
    background-color: #ABABAB;
    height: 1px;
    width: 40%;
}

.warning {
    color: rgb(255, 217, 0);
    text-align: left;
}

.sucess {
    color: white;
}

.registration-page {
    .tutorial-list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        padding: 10px !important;
        background-color: #FFF;
        max-width: 570px;
        width: 100%;
        margin: auto;
        margin-top: 20px;
        border-radius: 10px;

        .cart {
            width: 100%;
            background-color: #FFF;
            box-shadow: 1px 1px 1px 2px #8e727233;
            border-radius: 10px;
            overflow: hidden;

            .title {
                background-color: var(--default-color);
                padding: 10px 5px;

                p {
                    font-size: 22px;
                    font-weight: 600;
                    color: #FFF;

                }
            }

            .video-container {
                width: 100%;
                min-width: 100%;
                aspect-ratio: 1/.6 !important;
                padding: 0px;

                iframe {
                    width: 100%;
                    height: 100%;

                    * {
                        margin: 0px !important;
                        padding: 0px !important;
                    }

                }
            }

            .button-section {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;

                button {
                    background-color: var(--default-color);
                    padding: 5px 15;
                    font-weight: 700;
                    outline: none;
                    border: none;
                    color: #FFF;
                    border-radius: 4px;

                    &:hover {
                        opacity: .7;
                    }

                    &:active {
                        transform: scale(.95);
                    }
                }
            }
        }
    }
}

.form-navigation a, .form-navigation p {
    text-decoration: none;
    font-size: 17px;

}
.form-navigation a{
    padding: 3px 5px;
    background-color: var(--default-color);
    color: #FFF !important;
    border-radius: 4px;
}

.authentication form .resposeContainer {
    display: block;
    height: 20px !important;
}


/* ========================================== */

@media only screen and (min-width:200px) and (max-width:768px) {
    .authentication form label {
        padding-top: 14px !important;
    }

    .authentication form input,
    .authentication form .notice-container {
        margin: 10px 0px;
    }

    .authentication form h6,
    p {
        padding: 0 2px;
    }

    .authentication form {
        width: 100% !important;
        padding: 10px;
    }

    .socil-container {
        border-radius: 10px;
        width: 390px;
    }

    .socil-container div p {
        font-size: .9rem;
        font-weight: 600;
    }

    .socil-container div img {
        height: 30px;
    }

    .slocil-after::before {
        left: 0px;
    }

    .slocil-after::after {
        right: 0px;
    }

    .balance-transfer-section form input {
        width: 95% !important;
        margin: auto !important;
    }

    .balance-transfer-section form label {
        width: 95% !important;
        margin: auto !important;
        padding-left: 3px;

    }

}

@media only screen and (min-width:200px) and (max-width:576px) {
    .authentication form {
        width: 100%;
    }

    .authentication form h6 {
        font-weight: 580;
    }

    .socil-container {
        margin: auto;
    }

    .socil-container div {
        width: 300px;
    }

    .authentication form input {
        font-size: 14px;
        margin: auto !important;
    }

    .authentication form label {
        font-size: 18px;
        margin: auto !important;
    }

    .authentication .icon-container svg {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
    }

    .form-navigation {
        font-size: 17px;
        height: 30px !important;
        width: 100% !important;
    }

    .remember-me {
        font-size: 14px;
    }

    .forgot-password {
        font-size: 14px;
    }

    .forgot-p {
        font-size: 14px;
    }

    .socil-container {
        width: 300px;
        margin: auto;
    }

    .socil-container div {
        width: 200px;
    }

    .socil-container div p {
        font-size: .8rem;
        font-weight: 600;
    }

    .socil-container div img {
        height: 25px;
    }


}